import { FILE_RESOURCE_BASE_FRAGMENT } from '@/graphql/_Fragments/FileResource/Base';

export const EXHIBITOR_VIDEO_BASE_FRAGMENT = `
  fragment exhibitorVideoBaseFragment on ExhibitorVideo {
    uid
    schemaCode
    name
    url
    thumbnailFileResource {
      ...fileResourceBaseFragment
    }
    exhibitor {
      uid
      name
      logoFileResource {
        ...fileResourceBaseFragment
      }
    }
  }
  ${FILE_RESOURCE_BASE_FRAGMENT}
`;
